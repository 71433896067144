<template>
  <div id="antdVue" class="antdVue" :style="themeColorInit(themeColorConfig)">
    <!---弹性布局flex布局开始-->
    <div class="flex benben-flex-layout width_fullscreen flex-wrap  diypageaddress_flex_0">
      <a-form-model ref="formRef1685667688395" class="benben-flex-form diypageaddress_fd0_0" :model="queryParam" label-align="right" layout="inline" :label-col="{}" :wrapper-col="{}">
        <a-form-model-item need="false" :rules="[]" :colon="true" label="收货人姓名">
          <a-input class="diypageaddress_fd0_0_c0" v-model="queryParam.reciverName" :disabled="false" :maxlength="-1" placeholder="请输入" :allow-clear="true" size="default">
          </a-input>
        </a-form-model-item>
        <a-form-model-item need="false" :rules="[]" :colon="true" label="收货人电话">
          <a-input class="diypageaddress_fd0_0_c1" v-model="queryParam.reciverTelephone" :disabled="false" :maxlength="-1" placeholder="请输入" :allow-clear="true" size="default">
          </a-input>
        </a-form-model-item>
        <a-form-model-item need="false" :rules="[]" :colon="true" label="性别">
          <simple-select class="diypageaddress_fd0_0_c2" v-model="queryParam.sex" :disabled="false" placeholder="请选择" mode="default" :options="queryParamSex" :field-names='{"label":"name","value":"aid","children":"child"}'>

          </simple-select>
        </a-form-model-item>
        <a-button class="diypageaddress_fd0_0_c3" :disabled="false" type="primary" @click="getList()">查询</a-button>

        <a-button :disabled="false" @click="clearQueryParam()">重置</a-button>

        <a-row class="benben-grid width_fullscreen diypageaddress_fd0_0_c5" layout="24:18:6" :gutter="[8,8]">

          <a-col :span="24">
            <a-button :disabled="false" type="primary" @click="showDrawer()">+ 新增</a-button>


          </a-col>



          <a-col :span="18">

          </a-col>



          <a-col :span="6">

          </a-col>

        </a-row>
      </a-form-model>
    </div>
    <!---弹性布局flex布局结束-->

    <!---弹性布局flex布局开始-->
    <div class="flex benben-flex-layout width_fullscreen flex-wrap  diypageaddress_flex_1">
      <a-table tableLayout="fixed" :bordered="true" size="small" :pagination="false" :data-source="dataList" :columns="columns_1866" :row-key="record=>record.aid">
        <template slot="sex" slot-scope="text,record,index"><span v-if="record.sex == 1">男</span><span v-else-if="record.sex == 2">女</span><span v-else>未知</span></template>
        <template slot="operation" slot-scope="text,record,index">
          <a href="javascript:;" @click="showDrawer(record.id)" style="color:#409eff">编辑</a>
          <a-popconfirm title="确定删除?" @confirm="delData(record.id)"><a href="javascript:;" style="color:#f56c6c">删除</a></a-popconfirm>
        </template>

      </a-table>

    </div>
    <!---弹性布局flex布局结束-->

    <a-drawer id="antdVue" class="  diypageaddress_modal_2" :centered="false" :closable="false" :mask="true" :mask-closable="true" placement="right" title="添加地址" width="50%" height="50%" :z-index="1000" :maskClosable="true" :visible='showPage'>
      <a-form-model ref="formRef1685671235186" class="benben-flex-form diypageaddress_modal2_0" :model="formData" label-align="right" layout="horizontal" :label-col="{span:4,offset:0}" :wrapper-col="{span:20,offset:0}">
        <a-form-model-item prop="reciverName" need="true" :rules='[{"required":true,"message":"请填写信息","trigger":"blur"}]' :colon='true' label='收货人姓名'>
          <a-input class="diypageaddress_modal2_0_c0" v-model="formData.reciverName" :disabled="false" :maxlength="-1" placeholder="请输入" :allow-clear="true" size="default">
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="reciverTelephone" need="true" :rules='[{"required":true,"message":"请填写信息","trigger":"blur"}]' :colon='true' label='收货人电话'>
          <a-input class="diypageaddress_modal2_0_c1" v-model="formData.reciverTelephone" :disabled="false" :maxlength="-1" placeholder="请输入" :allow-clear="true" size="default">
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="sex" need="true" :rules='[{"required":true,"message":"请选择","whitespace":true,"trigger":["blur","change"]}]' :colon='true' label='性别'>
          <a-radio-group v-model="formData.sex" :disabled="false" tagType="button" button-style="solid">
            <a-radio-button value="1">
              男
            </a-radio-button>
            <a-radio-button value="2">
              女
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item prop="addressLabel" need="true" :rules='[{"required":true,"message":"请填写信息","trigger":"blur"}]' :colon='true' label='标签'>
          <a-input class="diypageaddress_modal2_0_c3" v-model="formData.addressLabel" :disabled="false" :maxlength="-1" placeholder="请输入" :allow-clear="true" size="default">
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="detailedAddress" need="true" :rules='[{"required":true,"message":"请填写信息","trigger":"blur"}]' :colon='true' label='详细地址'>
          <a-input class="diypageaddress_modal2_0_c4" v-model="formData.detailedAddress" :disabled="false" :maxlength="-1" placeholder="请输入" :allow-clear="true" size="default">
          </a-input>
        </a-form-model-item>
        <a-row class="benben-grid width_fullscreen diypageaddress_modal2_0_c5" layout="24:18:6" :gutter="[8,8]">

          <a-col :span="24" class="diypageaddress_modal2_0_c5_c0">
            <a-button :disabled="false" type="primary" @click="addOrUpdate()">确定</a-button>

            <a-button class="diypageaddress_modal2_0_c5_c0_c1" :disabled="false" @click="showDrawer()">取消</a-button>


          </a-col>



          <a-col :span="18">

          </a-col>



          <a-col :span="6">

          </a-col>

        </a-row>
      </a-form-model>
    </a-drawer>



  </div>
</template>

<script>
import {
  receive,
  benbenSelectDiy,
  benbenFlexNumberBox,
  scrollList,
  treeSelect,
  simpleSelect,
  multipleSelect,
  cascaderForm,
  checkboxForm,
  switchForm,
  messageReply,
  rangePicker,
  handSignature,
  mixinAddTableItem,
  editorForm,
  qiunDataCharts,
  clearDataPass,
  copyText,
  mixinTableItemChange,
  in_array,
  themeColorInit,
  getUrlKey
} from '@/assets/diypaaspc/diy-pass-pc.js'
import {
  provinceForm
} from '@/assets/diypaaspc/province_area.js'
import request from '@/utils/request'
export default {
  name: "goods",
  components: {
    qiunDataCharts,
    benbenSelectDiy,
    //            productSelect,
    benbenFlexNumberBox,
    scrollList,
    treeSelect,
    simpleSelect,
    messageReply,
    rangePicker,
    multipleSelect,
    provinceForm,
    editorForm,
    cascaderForm,
    switchForm,
    checkboxForm,
    //            uploadImage,
    //            uploadFile,
    //            uploadMedia,
    //            uploadSingleFile,
    handSignature
  },
  data() {
    receive(this);
    const themeColorConfig = {
      "presetApi": {
        "getClassifyOne": "",
        "getClassifyTow": "",
        "getClassifyShop": ""
      },
      "themeType": 0,
      "themeConfig": [{
        "colorConfig": ["#333333", "#666666", "#999999", "#ffffff", "#1178F0", "#F02325", "#FC9C3A", "#4DC777", "#F2CC98", "#F2CC66"],
        "bgColorConfig": ["#EBEDF8", "#FFFFFF", "#1178F0", "#FC9C3A", "#FC9fff", "#E6F333", "#E6F1FE", "linear-gradient(301deg, #EBBA7B 0%, #F8D9AD 100%)", "linear-gradient(180deg, #F5588E 0%, #F0403C 100%)", "linear-gradient(179deg, #FFD033 0%, #F4890E 100%)"],
        "bdColorConfig": ["#EBEDF8", "#FFFFFF", "#1178F0", "#FC9C3A", "#333333", "#666666", "#999999", "#dddddd", "#1178F0", "#F02325"]
      }]
    };
    return {
      themeColorConfig,
      "columns_1866": [{
        "show": false,
        "title": "收货人姓名",
        "dataIndex": "reciverName",
        "key": "1685668780442",
        "fixed": false,
        "align": "left",
        "ellipsis": true,
        "type": "text",
        "scopedSlots": {
          "customRender": "reciverName"
        },
        "slots": {
          "title": "customreciverName"
        }
      }, {
        "show": false,
        "title": "收货人电话",
        "dataIndex": "reciverTelephone",
        "key": "1685668335229",
        "sorter": false,
        "type": "text",
        "fixed": false,
        "align": "left",
        "scopedSlots": {
          "customRender": "reciverTelephone"
        },
        "slots": {
          "title": "customreciverTelephone"
        }
      }, {
        "show": false,
        "title": "性别",
        "dataIndex": "sex",
        "key": "1685669052870",
        "sorter": false,
        "type": "text",
        "fixed": false,
        "align": "left",
        "scopedSlots": {
          "customRender": "sex"
        },
        "slots": {
          "title": "customsex"
        }
      }, {
        "show": false,
        "title": "标签",
        "dataIndex": "addressLabel",
        "key": "1685668465810",
        "sorter": false,
        "type": "text",
        "fixed": false,
        "align": "left",
        "scopedSlots": {
          "customRender": "addressLabel"
        },
        "slots": {
          "title": "customaddressLabel"
        }
      }, {
        "show": false,
        "title": "详细地址",
        "dataIndex": "detailedAddress",
        "key": "1685668752136",
        "sorter": false,
        "type": "text",
        "fixed": false,
        "align": "left",
        "scopedSlots": {
          "customRender": "detailedAddress"
        },
        "slots": {
          "title": "customdetailedAddress"
        }
      }, {
        "show": true,
        "title": "操作",
        "dataIndex": "operation",
        "key": "operation",
        "fixed": false,
        "align": "left",
        "ellipsis": true,
        "type": "operation",
        "scopedSlots": {
          "customRender": "operation"
        }
      }],
      "queryParamSex": [{
        "aid": "1",
        "name": "男"
      }, {
        "aid": "2",
        "name": "女"
      }],
      "dataList": [],
      "queryParam": {
        "reciverName": "",
        "reciverTelephone": "",
        "sex": ""
      },
      "addressInfo": {
        "reciverTelephone": "",
        "areaxId": 0,
        "lng": 0,
        "areax": "",
        "sex": 0,
        "addressLabel": "",
        "userId": 0,
        "areap": "",
        "areacId": 0,
        "isDefault": 0,
        "reciverName": "",
        "detailedAddress": "",
        "areac": "",
        "id": 0,
        "lat": 0,
        "areapId": 0
      },
      "showPage": false,
      "operationRes": {
        "code": "",
        "data": "",
        "message": "",
        "success": false,
        "time": "",
        "errors": []
      },
      "formData": {
        "reciverTelephone": "",
        "areaxId": 0,
        "lng": 0,
        "areax": "",
        "sex": 0,
        "addressLabel": "",
        "userId": 0,
        "areap": "",
        "areacId": 0,
        "isDefault": 0,
        "reciverName": "",
        "detailedAddress": "",
        "areac": "",
        "id": 0,
        "lat": 0,
        "areapId": 0
      }
    };

  },
  computed: {

  },
  created() {
    this.getList()
  },
  mounted() {

  },
  beforeDestroy() {

  },
  watch: {},
  methods: {
    copyText,
    mixinTableItemChange,
    in_array,
    themeColorInit,
    //获取数据列表
    getList() {
      //请求方法
      //数据验证

      request({
        url: '/test/user/address',
        method: 'post',
        data: {
          reciverTelephone: this.queryParam.reciverTelephone,
          sex: this.queryParam.sex,
          reciverName: this.queryParam.reciverName
        }
      }).then((res) => {
        if (res.code != '20001') {
          this.$message.info(res.msg);
          return
        }
        let infodataList = res;

        this.dataList = infodataList.data



      })
    },
    //重置查询参数
    clearQueryParam() {
      this.queryParam.reciverName = '';
      this.queryParam.reciverTelephone = '';
      this.queryParam.sex = '';
      this.getList()
    },
    //显隐抽屉
    showDrawer(id) {
      if (this.showPage === true) {
        clearDataPass(this.formData)
        this.showPage = false;
      } else {
        this.showPage = true;
        if (id != undefined) {
          //请求方法
          //数据验证

          request({
            url: '/test/user/getAddressById',
            method: 'get',
            params: {
              id: id
            }
          }).then((res) => {
            if (res.code != '20001') {
              this.$message.info(res.msg);
              return
            }
            let infoaddressInfo = res;

            this.addressInfo = infoaddressInfo.data
            this.formData = this.addressInfo;


          })
        } else {
          clearDataPass(this.formData)
        }
      }
    },
    //获取详情
    getInfo(id) {
      //请求方法
      //数据验证

      request({
        url: '/test/user/getAddressById',
        method: 'get',
        params: {
          id: id
        }
      }).then((res) => {
        if (res.code != '20001') {
          this.$message.info(res.msg);
          return
        }
        let infoaddressInfo = res;

        this.addressInfo = infoaddressInfo.data



      })
    },
    //删除地址
    delData(id) {
      //请求方法
      //数据验证

      request({
        url: '/test/user/delAddressById',
        method: 'get',
        params: {
          id: id
        }
      }).then((res) => {
        if (res.code != '20001') {
          this.$message.info(res.msg);
          return
        }
        let infooperationRes = res;

        this.operationRes = infooperationRes.data
        this.getList()
        //成功提示
        this.$message.success("操作成功")

      })
    },
    //添加或修改
    addOrUpdate() {
      if (this.formData.id != "") {
        //请求方法
        //数据验证

        request({
          url: '/test/user/editAddress',
          method: 'post',
          data: {
            reciverTelephone: this.formData.reciverTelephone,
            sex: this.formData.sex,
            addressLabel: this.formData.addressLabel,
            reciverName: this.formData.reciverName,
            detailedAddress: this.formData.detailedAddress,
            id: this.formData.id
          }
        }).then((res) => {
          if (res.code != '20001') {
            this.$message.info(res.msg);
            return
          }
          let infooperationRes = res;

          this.operationRes = infooperationRes.data
          this.showDrawer()
          this.getList()


        })
      } else {
        //请求方法
        //数据验证

        request({
          url: '/test/user/addAddress',
          method: 'post',
          data: {
            reciverTelephone: this.formData.reciverTelephone,
            sex: this.formData.sex,
            addressLabel: this.formData.addressLabel,
            reciverName: this.formData.reciverName,
            detailedAddress: this.formData.detailedAddress
          }
        }).then((res) => {
          if (res.code != '20001') {
            this.$message.info(res.msg);
            return
          }
          let infooperationRes = res;

          this.operationRes = infooperationRes.data
          this.showDrawer()
          this.getList()
          //成功提示
          this.$message.success("操作成功")

        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import 'https://zhongtai.zhongbenruanjian.com/static/diypaaspc/css/diy-pass-pc.css';

.antdVue {
  width: 100%;
  background: #F8F8F8;
  background-size: 100% auto !important;
}

#antdVue .diypageaddress_flex_0 {
  background: #fff;
  width: 100%;
  min-height: 100px;
}

#antdVue .diypageaddress_fd0_0_c5 {
  background: #fff;
  width: 100%;
}

#antdVue .diypageaddress_fd0_0_c3 {
  margin: 4px 9px 0px 0px;
}

#antdVue .diypageaddress_fd0_0_c2 {
  width: 200px;
}

#antdVue .diypageaddress_fd0_0_c1 {
  font-size: 14px;
}

#antdVue .diypageaddress_fd0_0_c0 {
  font-size: 14px;
}

#antdVue .diypageaddress_fd0_0 {
  background: #fff;
  width: 100%;
  min-height: 100px;
}

#antdVue .diypageaddress_flex_1 {
  background: #fff;
  width: 100%;
  min-height: 100px;
}

#antdVue .diypageaddress_modal2_0_c5_c0_c1 {
  margin: 0px 0px 0px 10px;
}

#antdVue .diypageaddress_modal2_0_c5_c0 {
  text-align: right;
}

#antdVue .diypageaddress_modal2_0_c5 {
  background: #fff;
  width: 100%;
}

#antdVue .diypageaddress_modal2_0_c4 {
  font-size: 14px;
}

#antdVue .diypageaddress_modal2_0_c3 {
  font-size: 14px;
}

#antdVue .diypageaddress_modal2_0_c1 {
  font-size: 14px;
}

#antdVue .diypageaddress_modal2_0_c0 {
  font-size: 14px;
}

#antdVue .diypageaddress_modal2_0 {
  background: #fff;
  width: 100%;
  min-height: 100px;
}

#antdVue .diypageaddress_modal_2 {
  width: 100%;
}
</style>